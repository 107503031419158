import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol, toast, ToastContainer } from 'mdbreact'
import { Zoom } from 'react-toastify'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { Link } from 'gatsby'

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Name must be 2 characters at minimum')
    .required('Name is required'),
  email: Yup.string()
    .email('Invalid email address format')
    .required('Email is required'),
  street: Yup.string()
    .min(2, 'Street must be 5 characters at minimum')
    .required('Street is required'),
  city: Yup.string()
    .min(2, 'City must be 2 characters at minimum')
    .required('City is required'),
  state: Yup.string()
    .min(2, 'State must be 2 characters at minimum')
    .required('State is required'),
  zip: Yup.string()
    .min(2, 'Zip/postcode must be 5 characters at minimum')
    .required('Zip/postcode is required'),
  country: Yup.string()
    .min(2, 'Country must be 2 characters at minimum')
    .required('Country is required'),
  nosell: Yup.boolean()
    .oneOf([true], 'The do not sell my personal information field must be checked'),
  consentsubscribe: Yup.boolean()
    .oneOf([true], 'You need to provide consent to process your email address'),
  consentstore: Yup.boolean()
    .oneOf([true], 'You need to provide consent to store your data'),    
})

const url = 'https://script.google.com/macros/s/AKfycbyBDzVnF-VNosxoNgrjSd7qcs_KULsCi8qgakmqigqaMU4MseyT_PnX8zRpeG2CLiWt/exec'

const messageConfirm = () => {
  return toast.info("Thank you for contacting us.  We'll be in touch shortly!", { closeButton: false, position: toast.POSITION.TOP_CENTER, transition: Zoom })
}

class formCCPA extends Component {
  constructor(props, ...args) {
    super(props, ...args)
    this.state = {
      callback: 'not fired',
      value: null,
      pageUrl: null, 
      load: false,
      expired: 'false',
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ 
        load: true, 
        pageUrl: location.href 
      })
    })
  }

  handleChange = value => {
    this.setState({ value })
  }

  asyncScriptOnLoad = () => {
    this.setState({ callback: 'called!' })
  }

  render() {
    const { value, callback, load, expired } = this.state || {}

    return (
        <MDBContainer>
        <MDBRow className="justify-content-center">
          <MDBCol md="6" className="md-0 mb-5">
          <Formik
              initialValues={{
                name: '',
                email: '',
                street: '',
                city: '',
                state: '',
                zip: '',
                country: '',
                nosell: false,
                consentsubscribe: false,
                consentstore: false,                
              }}
              validationSchema={validationSchema}
              onSubmit={(values, {setSubmitting, resetForm}) => {
                /* alert('Form is validated! Submitting the form...') */
                let formData = {
                  PageUrl: this.state.pageUrl,
                  Name: values.name,
                  Email: values.email,
                  Street: values.street,
                  City: values.city,
                  State: values.state,
                  Zip: values.zip,
                  Country: values.country,
                  NoSell: values.nosell,
                  Subscribe: values.consentsubscribe,
                  Store: values.consentstore,                  
                }
                try {
                  const response = axios({
                      method: 'get',
                      url: `${url}?timestamp=&url=${encodeURIComponent(this.state.pageUrl)}&name=${encodeURIComponent(values.name)}&email=${encodeURIComponent(values.email)}&street=${encodeURIComponent(values.street)}&city=${encodeURIComponent(values.city)}&state=${encodeURIComponent(values.state)}&zip=${encodeURIComponent(values.zip)}&country=${encodeURIComponent(values.country)}&nosell=${encodeURIComponent(values.nosell)}&subscribe=${encodeURIComponent(values.consentsubscribe)}&store=${encodeURIComponent(values.consentstore)}`
                    })
                      //console.log(response)
                      this.setState({values: '', expired: 'true'})
                      resetForm()
                      messageConfirm()
                    } catch (e) {
                      console.log(`Axios request failed: ${e}`)
                    }
                  setSubmitting(false)
                }}
            >
              {({ touched, errors, values, isSubmitting }) => (
                <Form
                  name="contact"
                  method="post"
                  action=""
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                >
                  <input type="hidden" name="bot-field" />
                  <input type="hidden" name="form-name" value="contact" />
                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="name">
                          Name <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Field
                          type="name"
                          name="name"
                          aria-label="name"
                          value={values.name}
                          placeholder="Your full name"
                          className={`form-control ${ touched.name && errors.name ? 'is-invalid' : '' }`}
                        />
                        <ErrorMessage component="div" name="name" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="email">
                          Email <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Field
                          type="email"
                          name="email"
                          aria-label="email"
                          value={values.email}
                          placeholder="Your email address"
                          className={`form-control ${ touched.email && errors.email ? 'is-invalid' : '' }`}
                        />
                        <ErrorMessage component="div" name="email" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-700 text-medium">
                        <label htmlFor="street">
                          Street <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Field
                          type="textarea"
                          component="textarea"
                          name="street"
                          value={values.street}
                          placeholder="Enter street"
                          className={`form-control ${ touched.street && errors.street ? 'is-invalid' : '' }`}
                        />
                        <ErrorMessage component="div" name="street" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                  </MDBRow>
                  
                  <MDBRow>
                    <MDBCol md="4">
                      <div className="mb-4 grey-text font-w-700 text-medium">
                        <label htmlFor="city">
                          City <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Field
                          type="text"
                          name="city"
                          value={values.city}
                          placeholder="Your city of residence"
                          className={`form-control ${ touched.city && errors.city ? 'is-invalid' : '' }`}
                        />
                        <ErrorMessage component="div" name="city" className="invalid-feedback" />
                      </div>
                    </MDBCol>

                    <MDBCol md="4">
                      <div className="mb-4 grey-text font-w-700 text-medium">
                        <label htmlFor="state">
                          State <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Field
                          type="text"
                          name="state"
                          value={values.state}
                          placeholder="Your state of residence"
                          className={`form-control ${ touched.state && errors.state ? 'is-invalid' : '' }`}
                        />
                        <ErrorMessage component="div" name="state" className="invalid-feedback" />
                      </div>
                    </MDBCol>                   

                    <MDBCol md="4">
                      <div className="mb-4 grey-text font-w-700 text-medium">
                        <label htmlFor="zip">
                          Zip/postcode <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Field
                          type="text"
                          name="zip"
                          value={values.zip}
                          placeholder="Your zip/postcode of residence"
                          className={`form-control ${ touched.zip && errors.zip ? 'is-invalid' : '' }`}
                        />
                        <ErrorMessage component="div" name="zip" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-700 text-medium">
                        <label htmlFor="country">
                          Country <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Field
                          type="text"
                          name="country"
                          value={values.country}
                          placeholder="Your country of residence"
                          className={`form-control ${ touched.country && errors.country ? 'is-invalid' : '' }`}
                        />
                        <ErrorMessage component="div" name="country" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="form-group form-check mt-5 grey-text font-w-400 text-small">
                        <Field
                          type="checkbox"
                          id="nosell"
                          name="nosell"
                          className={`form-check-input ${ touched.nosell && errors.nosell ? 'is-invalid' : '' }`}
                        />
                        <label htmlFor="nosell">
                          Do not sell my personal information <span style={{ color: 'red' }}>*</span>
                        </label>
                        <ErrorMessage name="nosell" component="div" className="invalid-feedback font-w-700 text-medium-small" />
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mt-3 mb-4 grey-text font-w-400 text-small">
                      By ticking the checkbox and clicking the "Submit" button, you agree that DETEC Software GmbH may process your email address provided in the contact form in order to inform you about products, services, news and events of DETEC Software GmbH within the scope of a newsletter. The legal basis for the processing is Art. 6 para. 1 lit a) GDPR. Your consent given here is voluntary and can be revoked at any time and free of charge for the future.
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="form-group form-check mt-3 mb-2 grey-text font-w-400 text-small">
                        <Field
                          type="checkbox"
                          id="consentsubscribe"
                          name="consentsubscribe"
                          aria-label="consentsubscribe"
                          className={`form-check-input ${ touched.consentsubscribe && errors.consentsubscribe ? 'is-invalid' : '' }`}
                        />
                        <label htmlFor="consentsubscribe">
                          Yes, I agree. <span style={{ color: 'red' }}>*</span>
                        </label>
                        <ErrorMessage name="consentsubscribe" component="div" className="invalid-feedback font-w-700 text-medium-small" />
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mt-3 mb-4 grey-text font-w-400 text-small">
                      By ticking the checkbox and clicking the "Submit" button, you agree that DETEC Software GmbH may store your data provided in the contact form on its servers and IT systems and process it to respond to your inquiry. The legal basis for the processing is Art. 6 para. 1 lit. a) GDPR Your consent given here is voluntary and can be revoked at any time and free of charge for the future. The detailed privacy policy including your rights and revocation options can be found under the <Link to="/privacy-statement/" className="effect">Privacy Statement</Link>.
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="form-group form-check mt-3 mb-2 grey-text font-w-400 text-small">
                        <Field
                          type="checkbox"
                          id="consentstore"
                          name="consentstore"
                          aria-label="consentstore"
                          className={`form-check-input ${ touched.consentstore && errors.consentstore ? 'is-invalid' : '' }`}
                        />
                        <label htmlFor="consentstore">
                          Yes, I agree. <span style={{ color: 'red' }}>*</span>
                        </label>
                        <ErrorMessage name="consentstore" component="div" className="invalid-feedback font-w-700 text-medium-small" />
                      </div>
                    </MDBCol>
                  </MDBRow>                  

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '120px' }}>
                        <button
                          type="submit"
                          className="mt-4 btn btn-medium btn-unicom"
                          style={{ display: 'block' }}
                          disabled={ isSubmitting ? true : false }
                        >
                          {isSubmitting ? 'Please wait...' : 'Submit'}
                        </button>
                        <ToastContainer
                          transition={Zoom}
                          hideProgressBar={true}
                          newestOnTop={true}
                          autoClose={5000}
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>
                </Form>
              )}
            </Formik>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
  )}
}

export default formCCPA